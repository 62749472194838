import {useState} from "react";
import './App.css';

const ProductBox = ({ children , link}) => {
    const [isHovering, setIsHovering] = useState(false);

    const handleMouseEnter = () => {
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };

    const boxShadowIntensity = isHovering ? '0px 18px 18px rgba(0, 0, 0, 0.5)' : '0px 2px 4px rgba(0, 0, 0, 0.1)';
    const scale = isHovering ? 1.1 : 1;

    return (
        <div
            className="ProductBoxContainer"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={() => window.open(link)}
            style={{
                boxShadow: boxShadowIntensity,
                transform: `scale(${scale})`,
                zIndex: isHovering ? 9999 : 'unset',
                transition: 'box-shadow 0.3s, transform 0.3s, border 0.3s',
            }}
        >
            {children}
        </div>
    );
};

export default ProductBox