// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Reset default margin and padding for all elements */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box; /* Include border and padding in element's total width/height */
}

/* Apply font family to all elements */
body {
    font-family: 'Cairo', Arial, serif;
}

/* Apply linear gradient background to HTML */
html {
    background: linear-gradient(360deg, #0F001CFF, #034461, #0F001CFF);
    background-size: 800% 800%;
    height: 100vh;
    animation: AnimationName 30s ease infinite;
}

/* Define the animation keyframes */
@keyframes AnimationName {
    0% { background-position: 30% 0%; }
    50% { background-position: 70% 100%; }
    100% { background-position: 30% 0%; }
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,sDAAsD;AACtD;IACI,SAAS;IACT,UAAU;IACV,sBAAsB,EAAE,+DAA+D;AAC3F;;AAEA,sCAAsC;AACtC;IACI,kCAAkC;AACtC;;AAEA,6CAA6C;AAC7C;IACI,kEAAkE;IAClE,0BAA0B;IAC1B,aAAa;IACb,0CAA0C;AAC9C;;AAEA,mCAAmC;AACnC;IACI,KAAK,2BAA2B,EAAE;IAClC,MAAM,6BAA6B,EAAE;IACrC,OAAO,2BAA2B,EAAE;AACxC","sourcesContent":["/* Reset default margin and padding for all elements */\n* {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box; /* Include border and padding in element's total width/height */\n}\n\n/* Apply font family to all elements */\nbody {\n    font-family: 'Cairo', Arial, serif;\n}\n\n/* Apply linear gradient background to HTML */\nhtml {\n    background: linear-gradient(360deg, #0F001CFF, #034461, #0F001CFF);\n    background-size: 800% 800%;\n    height: 100vh;\n    animation: AnimationName 30s ease infinite;\n}\n\n/* Define the animation keyframes */\n@keyframes AnimationName {\n    0% { background-position: 30% 0%; }\n    50% { background-position: 70% 100%; }\n    100% { background-position: 30% 0%; }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
