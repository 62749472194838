import './App.css';
import YDLogo from "./assets/logo.svg";
import StudiaLogo from '../src/assets/studiaLogo.svg'
import MentallyLogo from '../src/assets/mentallyLogo.svg'
import TelepatiLogo from '../src/assets/telepatiLogo.svg'
import YesilLogo from '../src/assets/yesilLogo.svg'
import ProductBox from '../src/ProductBox'
import {useState} from 'react'
import {MdDownloadForOffline} from 'react-icons/md'
import {BiSolidDownload} from "react-icons/bi";

const Studia = () => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleDownload = () => {

    }

    return (
        <div className={"StudiaContainer"} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <img src={StudiaLogo} className={"StudiaLogo"} alt="Studia Logo" />
                <div className={"StudiaDescription"} style={isHovered ? {size: 0} : null}>
                    An E-learning Platform of your own, Studia offers rich content and online training tools for your employees.
                    <button className={'DownloadButtonStyle'} onClick={handleDownload}>
                        <MdDownloadForOffline />
                    </button>
                </div>
        </div>
    );
};

const Mentally = () => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleDownload = () => {

    }

    return (
        <div
            className={"MentallyContainer"}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <img src={MentallyLogo} className={"MentallyLogo"} alt="Mentally Logo" />
                <div className={"MentallyDescription"} style={isHovered ? {size: 0} : null}>
                    A new generation therapy experience whenever you need it, wherever you want! Personalized, Fast and Secure
                    <button className={'DownloadButtonStyle'} onClick={handleDownload}>
                        <MdDownloadForOffline />
                    </button>
                </div>
        </div>
    );
};

const Telepati = () => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleDownload = () => {

    }

    return (
        <div
            className={"TelepatiContainer"}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <img src={TelepatiLogo} className={"TelepatiLogo"} alt="Telepati Logo" />
                <div className={"TelepatiDescription"} style={isHovered ? {size: 0} : null}>
                    Cloud video conferencing service where you can easily make all your video calls from your private life to your business life.
                    <button className={'DownloadButtonStyle'} onClick={handleDownload}>
                        <MdDownloadForOffline />
                    </button>
                </div>
        </div>
    );
};



const YesilMasa = () => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleDownload = () => {

    }

    return (
        <div
            className={"YesilContainer"}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <img src={YesilLogo} className={"YesilLogo"} alt="Mentally Logo" />
            <div className={"YesilDescription"} style={isHovered ? {size: 0} : null}>
                Discover a new era of assistance, designed around you. Get fast, secure, and tailored solutions whenever you need them.
                <button className={'DownloadButtonStyle'} onClick={handleDownload}>
                    <MdDownloadForOffline />
                </button>
            </div>
        </div>
    );
};



function App() {
    const [logoHovered, setLogoHovered] = useState(false);

    const handleLogoClick = () => {
        window.open("https://www.ydyazilim.com/", '_blank');
    };

    const scale = logoHovered ? 1.3 : 1;


    return (
        <div className="Container">
            <div className={"Header"}>
                <img
                    src={YDLogo}
                    className={`Logo`}
                    onMouseEnter={() => setLogoHovered(true)}
                    onMouseLeave={() => setLogoHovered(false)}
                    onClick={handleLogoClick}
                    alt="YD Logo"
                    style={{
                        transform: `scale(${scale})`,
                        transition: 'box-shadow 0.3s, transform 0.3s, border 0.3s',
                    }}
                />
            </div>
            <div className={"WhiteHeading"}>
                Discover our
            </div>
            <div className={"Heading"}>
                Latest Products
            </div>
            <div className={"BodyContainer"}>
                <ProductBox children={<Studia/>} link={"https://yya.ydyazilim.com/"}/>
                <ProductBox children={<Mentally/>} link={"https://www.ydyazilim.com/products/mentally"}/>
                <ProductBox children={<Telepati/>} link={"https://www.ydyazilim.com/products/telepati"}/>
                <ProductBox children={<YesilMasa/>} link={"http://onlinecozummerkezi.ydyazilim.com/"}/>
            </div>
        </div>
    );
}

export default App;
